.footer-text {
  text-align: right;
  color: #868e96 !important;
}

.dark-mode {
  color: white !important;
}

.footer-div {
  margin-top: 1rem;
  padding-bottom: 0rem;
}
